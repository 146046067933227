import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Фудтрак Крошка Ли"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Фудтрак Крошка Ли"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img
				src={DesktopMediaMainBanner}
				alt="Фудтрак Крошка Ли"
				loading="lazy"
			/>
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Открытие фудтрака с&nbsp;кыстыбышками.{" "}
				<span className={styles.heading}>
					Кейс <span className={styles.quote}>«Крошка Ли»</span>
				</span>
			</>
		),
	},
	heading: "Фудтрак как безопасный вариант бизнеса: опыт петербургского шефа",
	textarea: (
		<p className={styles.marginParagraph}>
			Владимир Иванюгин из&nbsp;Санкт-Петербурга окончил кулинарный колледж и
			попробовал себя в&nbsp;разных заведениях: от&nbsp;столовой
			до&nbsp;ресторанов тайской, итальянской, японской, мексиканской, русской,
			датской и&nbsp;ливанской кухонь. Работал в&nbsp;Сочи, Кемерово, Кранево
			(Болгария), Санкт-Петербурге и Ленинградской области. Летом
			2022&nbsp;года, вместе с&nbsp;братом Михаилом, открыл фудтрак татарской
			кухни &laquo;Крошка Ли&raquo;. Владимир рассказывает об&nbsp;опыте
			создания мобильного бизнеса, особенностях формата и&nbsp;о&nbsp;том, как
			развиваться в&nbsp;любимом деле.
		</p>
	),
	video: {
		src: "https://www.youtube.com/embed/-lWguAnlpMM",
		textarea: (
			<p className={styles.textarea}>
				Почему открыть фудтрак безопаснее, чем бар
			</p>
		),
	},
}
