import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"
import PromptIconSeven from "./assets/PromptIconSeven.png"
import PromptIconEight from "./assets/PromptIconEight.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"
import MediaContentSeven from "./assets/MediaContentSeven.webp"
import MediaContentEight from "./assets/MediaContentEight.webp"
import MediaContentNine from "./assets/MediaContentNine.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "Между баром и фудтраком выбрали безопасный вариант",
		textarea: (
			<>
				У&nbsp;меня были накопления&nbsp;&mdash; 2&nbsp;млн рублей. Думал, что
				на&nbsp;них открыть: бар или фудтрак. Многие считают, что лучший способ
				&laquo;приземлить&raquo; деньги&nbsp;&mdash; купить помещение
				и&nbsp;открыть ресторан или бар, который будет стабильно кормить.
				Но&nbsp;это вариант для тех, кто готов поседеть через пять лет. Потому
				что спокойным такой бизнес быть не&nbsp;может.
				<br />
				<br />
				<strong>
					Если открыть заведение не&nbsp;в&nbsp;том месте&nbsp;&mdash; можно
					потерять всё.
				</strong>{" "}
				Так было с&nbsp;нашим итальянским рестораном на&nbsp;Васильевском
				острове. Мы&nbsp;не&nbsp;учли, что местная аудитория любит тусить
				и&nbsp;отдыхать. Не&nbsp;переняли опыт других ресторанов выходного дня,
				которые там также закрывались. Тогда мы&nbsp;потеряли 2,5 млн рублей,
				а&nbsp;взамен получили жёсткий опыт.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img
					className={classNames(styles.icon, styles.icon__one)}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>
					Открыть ресторан или бар&nbsp;&mdash; вариант для тех, кто готов
					поседеть через пять лет
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentOne
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentOne}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentOne}
							alt="владелец фудтрака Крошка Ли"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					На открытие «Крошки Ли» мы потратили 2 млн рублей
				</p>
			</div>
		),
		textarea2: (
			<>
				В&nbsp;этот раз мы&nbsp;решили пойти безопасным путём&nbsp;&mdash;
				запустить фудтрак. Это фановый формат: поставил фургон, закупился
				в&nbsp;магазине, потусил за&nbsp;прилавком, поделил деньги
				с&nbsp;партнёром и&nbsp;счастливый пошёл домой. И&nbsp;так каждый день.
				А&nbsp;если концепция не&nbsp;взлетит&nbsp;&mdash; можно продать машину
				и&nbsp;оборудование и&nbsp;окупить часть вложений. Итого
				на&nbsp;открытие &laquo;Крошки&nbsp;Ли&raquo; мы&nbsp;потратили
				2&nbsp;млн рублей: начиная от&nbsp;покупки фудтрака и&nbsp;заканчивая
				кассовой лентой.
			</>
		),
	},
	{
		heading: "Чтобы зарабатывать на фудтраке, нужно заранее многое продумать",
		textarea: (
			<>
				В&nbsp;отличие от&nbsp;Америки и&nbsp;Европы, у&nbsp;нас нет чётких
				законов для передвижных предприятий общественного питания&nbsp;&mdash;
				всё не&nbsp;так прозрачно и&nbsp;понятно. И&nbsp;это первый момент,
				который нужно досконально изучить перед запуском бизнеса.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>
					За&nbsp;первые два месяца после{" "}
					<br className={styles.contentSmallShow} /> открытия продали 2&nbsp;500
					кыстыбышек
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentTwo)}>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentTwo}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTwo}
							alt="передвижное предприятие питания"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwo)}>
					В России нет чётких законов для передвижных предприятий общественного
					питания
				</p>
			</div>
		),
		textarea2: (
			<>
				Второй момент&nbsp;&mdash; локация. Прежде чем купить фудтрак, нужно
				на&nbsp;сто процентов определиться с&nbsp;тем, куда с&nbsp;ним встать
				и&nbsp;на&nbsp;какие фестивали ездить.{" "}
				<strong>Как найти подходящее место?</strong> Садишься в&nbsp;машину,
				запускаешь видеорегистратор и&nbsp;записываешь трафик: утренний,
				обеденный, вечерний, ночной. Считаешь и&nbsp;с&nbsp;точностью
				до&nbsp;95% предсказываешь, можно здесь поставить фудтрак или
				не&nbsp;стоит.
			</>
		),
	},
	{
		heading: "Концепцию придумали дома «на коленке»",
		textarea: (
			<>
				Моя жена&nbsp;&mdash; татарка, поэтому и&nbsp;кухню мы&nbsp;выбрали
				татарскую. Название связано с&nbsp;её&nbsp;именем Линара. Мы&nbsp;искали
				продукт с&nbsp;маржинальностью, как у&nbsp;кофе. Выбор пал
				на&nbsp;кыстыбый. Конечно, людей с&nbsp;ним нужно знакомить
				и&nbsp;объяснять, что это не&nbsp;чебуреки и&nbsp;не&nbsp;пирожки.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>В час мы продаём 30 кыстыбышек</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentThree)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentThree}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentThree}
							alt="концепция фудтрака"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descThree)}>
					Кыстыбый — простое националь
					<span className={styles.contentSmallShowInline}>-</span>ное блюдо в
					татарской кухне
				</p>
			</div>
		),
		textarea2: (
			<>
				За&nbsp;первые два месяца после открытия мы&nbsp;продали 2&nbsp;500
				кыстыбышек и&nbsp;поняли, что концепция нравится людям и&nbsp;они
				возвращаются. А&nbsp;если концепция клёвая, она будет приносить деньги.
				Кыстыбый&nbsp;&mdash; простое национальное блюдо в&nbsp;татарской кухне.
				У&nbsp;нас есть около 15&nbsp;разных историй, которые
				мы&nbsp;рассказываем гостям: кому-то говорим, что это татарский тако,
				другим&nbsp;&mdash; что это лепёшка с&nbsp;пюрешкой. В&nbsp;прямом
				смысле показываем на&nbsp;пальцах&nbsp;&mdash; складываем ладоши
				и&nbsp;объясняем о&nbsp;внутренней начинке.
			</>
		),
	},
	{
		heading: "Новый фудтрак или б/у — зависит от концепции",
		textarea: (
			<>
				При разработке концепции мы&nbsp;не&nbsp;знали, сколько понадобится
				оборудования. Поэтому решили, что лучше купить заряженный всем
				необходимым фудтрак. Сейчас мы&nbsp;понимаем&nbsp;&mdash; он&nbsp;велик,
				хватило&nbsp;бы и&nbsp;половины. При таком приобретении вход
				в&nbsp;бизнес составил&nbsp;бы 1&nbsp;млн рублей. На&nbsp;старте это
				не&nbsp;предугадать, и&nbsp;опыт здесь не&nbsp;поможет.{" "}
				<strong>Не&nbsp;попробуешь&nbsp;&mdash; не&nbsp;узнаешь.</strong> Чтобы
				реализовать потенциал этого фудтрака, мы&nbsp;участвуем с&nbsp;ним
				в&nbsp;больших фестивалях. Скорость продажи кыстыбый с&nbsp;нашим
				оборудованием&nbsp;&mdash; 2&nbsp;минуты, то&nbsp;есть в&nbsp;час
				мы&nbsp;продаём 30&nbsp;кыстыбышек.
			</>
		),
		// prompt: (
		// 	<div
		// 		className={classNames(
		// 			styles.promptTypeOne,
		// 			styles.promptIconThree
		// 			// styles.contentDesktop
		// 		)}
		// 	>
		// 		<img
		// 			className={classNames(styles.icon, styles.icon__three)}
		// 			src={PromptIconThree}
		// 			alt="Иконка 3"
		// 			loading="lazy"
		// 		/>
		// 		<p>В час мы продаём 30 кыстыбышек</p>
		// 	</div>
		// ),
	},
	{
		heading: "Мы постоянно в процессе: работаем и параллельно учимся",
		textarea: (
			<>
				Готовить аутентичные кыстыбышки нас учила повар из&nbsp;Уфы&nbsp;&mdash;
				тётя моей жены. Она 40&nbsp;лет кормила главу Башкортостана.
				На&nbsp;обучение ушла неделя, и&nbsp;мы&nbsp;сразу встали
				на&nbsp;фестивале в&nbsp;&laquo;Севкабель Порту&raquo;. А&nbsp;через
				несколько недель переехали на&nbsp;постоянное место во&nbsp;&laquo;Двор
				Гостинки&raquo;. Итого, месяц на&nbsp;подготовку базы и&nbsp;ещё месяц
				на&nbsp;пробу пера&nbsp;&mdash; это самый быстрый проект за&nbsp;всю мою
				историю. Для сравнения, первый фудтрак мы&nbsp;открывали в&nbsp;два раза
				дольше.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>Готовить кыстыбышки учились у повара главы Башкортостана</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentFour
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentFour}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFour}
							alt="затраты на запуск фудтрака"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFour)}>
					«Крошку Ли» мы запустили за 2 месяца
				</p>
			</div>
		),
		textarea2: (
			<>
				Бизнес так закрутился, что отвлекаться, в&nbsp;том числе
				на&nbsp;обучение, времени нет. После колледжа я&nbsp;ещё проходил разные
				курсы (в&nbsp;основном по&nbsp;программам автоматизации). Сейчас
				физически это не&nbsp;успеваю делать, и&nbsp;всё новое узнаю
				в&nbsp;процессе. О&nbsp;новостях из&nbsp;сферы узнаём
				в&nbsp;инстаграмах* известных людей&nbsp;&mdash; в&nbsp;основном
				шеф-поваров московских и&nbsp;питерских заведений. Ещё мне, например,
				нравится аккаунт американской кулинарной компании Blue Apron. Смотрю,
				экспериментирую и&nbsp;ввожу что-то в&nbsp;свой проект.
				<br />
				<br />
				<strong>
					Пока мы&nbsp;никого не&nbsp;нанимаем, потому что учимся продавать наш
					продукт сами.
				</strong>{" "}
				Мы&nbsp;с&nbsp;Мишей работаем в&nbsp;фудтраке с&nbsp;утра
				до&nbsp;позднего вечера. Закупаем продукты, готовим заготовки
				и&nbsp;основу для кыстыбый, занимаемся рекламой, корректируем меню
				исходя из&nbsp;сезонности и&nbsp;потребностей гостей, подаем заявки
				на&nbsp;участие в&nbsp;фестивалях. Моя жена помогает: если
				мы&nbsp;работаем на&nbsp;людном фестивале&nbsp;&mdash; стоит
				на&nbsp;кассе, а&nbsp;ещё считает и&nbsp;складывает в&nbsp;копилку
				деньги. Исходя из&nbsp;опыта, пишем чек-листы для будущих сотрудников.
			</>
		),
	},
	{
		heading: "Своя фабрика-кухня — это удобно, когда планируешь расширяться",
		textarea: (
			<>
				Ингредиенты мы&nbsp;закупаем у&nbsp;локальных поставщиков или
				в&nbsp;METRO&nbsp;&mdash; там хорошие продукты, цены, всё в&nbsp;одном
				месте. Заготовки делаем на&nbsp;своей фабрике в&nbsp;арендованном
				помещении. Для концепции фудтрака своя фабрика-кухня
				не&nbsp;нужна&nbsp;&mdash; можно закупаться каждое утро в&nbsp;магазине
				и&nbsp;готовить на&nbsp;месте. Но&nbsp;нам так удобнее, потому что
				мы&nbsp;обслуживаем мероприятия.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>«Крошка Ли» может обслужить мероприятие до 500 человек</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentFive)}>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={MediaContentFive}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFive}
							alt="фабрика-кухня"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFive)}>
					Мы работаем только с именитыми шефами
				</p>
			</div>
		),
		textarea2: (
			<>
				При готовке на&nbsp;месте &laquo;Крошка&nbsp;Ли&raquo; может обслужить
				мероприятие до&nbsp;500&nbsp;человек, наш второй фудтрак
				&laquo;Желток&raquo;&nbsp;&mdash; до&nbsp;200&nbsp;человек.
				Со&nbsp;своей фабрикой продукты можно подвозить по&nbsp;ходу
				мероприятия&nbsp;&mdash; тогда возможности по&nbsp;обслуживанию
				ограничиваются только собственными силами. А&nbsp;ещё мы&nbsp;строим
				второй фудтрак с&nbsp;петербургской интеллигентной шавермой
				и&nbsp;планируем развитие сетевого стритфуда с&nbsp;разными концепциями.
			</>
		),
	},
	{
		heading:
			"Каждый мужчина в 40 лет должен открыть что-то своё и зарабатывать сколько хочет",
		textarea: (
			<>
				Сложно строить ресторанную карьеру в&nbsp;найме. Сначала ты&nbsp;стоишь
				мало. Потом набираешься опыта и&nbsp;стоишь дорого, но&nbsp;тебе уже
				40&nbsp;и&nbsp;тебя никуда не&nbsp;берут. Поэтому ты&nbsp;плюёшь
				на&nbsp;всё: открываешь бизнес и&nbsp;зарабатываешь сколько хочешь.
				На&nbsp;любимом деле можно спокойно зарабатывать 100, 200, 300 тысяч
				рублей.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>На фестивалях за раз мы можем заработать и 100 тысяч</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentSix)}>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={MediaContentSix}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSix}
							alt="заработок в фудтраке"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSix)}>
					Моя жена — татарка, поэтому кухню выбрали татарскую. Название связано
					с её именем Линара
				</p>
			</div>
		),
		textarea2: (
			<>
				Сейчас наша средняя прибыль&nbsp;&mdash; 15&nbsp;тысяч рублей
				в&nbsp;день, а&nbsp;на&nbsp;фестивалях за&nbsp;раз мы&nbsp;можем
				заработать и&nbsp;100&nbsp;тысяч. Но&nbsp;можем
				и&nbsp;потерять&nbsp;&mdash; если не&nbsp;учтём вопросы аренды
				и&nbsp;локации. В&nbsp;истории с&nbsp;фестивалями важно понимать, куда
				ты&nbsp;едешь. Если это мероприятие с&nbsp;огромным количеством людей,
				например концерт группы &laquo;Руки Вверх&raquo;, то&nbsp;шанс получить
				прибыль увеличивается. Схема простая: чтобы быть в&nbsp;плюсе, нужно
				место с&nbsp;трафиком, который позволит продавать продукт раз в&nbsp;две
				минуты.
			</>
		),
	},
	{
		heading:
			"За 20 лет в ресторанном бизнесе я понял, что здесь не бывает легко",
		textarea: (
			<>
				Лет пять назад начался застой (карьерный, финансовый
				и&nbsp;эмоциональный) и&nbsp;я&nbsp;хотел уйти из&nbsp;этой сферы. Тогда
				я&nbsp;начитался книг, насмотрелся мастер-классов, походил
				по&nbsp;ресторанам, съездил в&nbsp;отпуск. По&nbsp;возвращению определил
				карьерный вектор, устроился в&nbsp;ресторан, выбрал график, который меня
				устраивал. Так появилось свободное время и&nbsp;возможность
				экспериментировать&nbsp;&mdash; и&nbsp;всё понеслось на&nbsp;взлёт.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSeven)}>
				<img
					className={classNames(styles.icon, styles.icon__seven)}
					src={PromptIconSeven}
					alt="Иконка 7"
					loading="lazy"
				/>
				<p>
					Я&nbsp;смотрю на&nbsp;десятилетие вперёд и&nbsp;с&nbsp;учётом этого
					строю бизнес
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentSeven)}
			>
				<Fancybox>
					<a
						data-fancybox="image7"
						href={MediaContentSeven}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSeven}
							alt="ресторанный бизнес"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSeven)}>
					Чтобы быть в&nbsp;плюсе, нужно место с&nbsp;трафиком и&nbsp;продажа
					продукта раз в&nbsp;2&nbsp;минуты
				</p>
			</div>
		),
		textarea2: (
			<>
				Путь к&nbsp;успеху зависит от&nbsp;цели в&nbsp;жизни. Если хочешь просто
				заработать&nbsp;&mdash; то&nbsp;откроешь коммерческий проект
				на&nbsp;1,5&ndash;2&nbsp;года. А&nbsp;если хочешь всю жизнь заниматься
				любимым делом&nbsp;&mdash; будешь смотреть на&nbsp;десятилетие вперёд
				и&nbsp;с&nbsp;учётом этого строить бизнес. Я&nbsp;из&nbsp;последних.
			</>
		),
	},
	{
		heading: "С Quick Resto любой может стать бизнесменом",
		textarea: (
			<>
				Фудтрак для &laquo;Крошки&nbsp;Ли&raquo; я&nbsp;купил у&nbsp;питерского
				ресторатора Жени Викентьева. При покупке его мама посоветовала мне
				программу автоматизации Quick Resto. Я&nbsp;без обучения соединил все
				проводки, подключил кассу, оплатил лицензию. Это заняло несколько часов,
				хотя с&nbsp;другими сервисами понадобилась&nbsp;бы минимум неделя. Любой
				человек может стать бизнесменом&nbsp;&mdash; достаточно купить планшет,
				установить Quick Resto, завести туда позиции и&nbsp;начать торговать.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentEight
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image8"
						href={MediaContentEight}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentEight}
							alt="система автоматизации фудтрака"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descEight)}>
					Любой может стать бизнесменом — достаточно установить Quick Resto
				</p>
			</div>
		),
		textarea2: (
			<>
				Программа справляется со&nbsp;всеми задачами: можно в&nbsp;реальном
				времени следить за&nbsp;выручкой, чеками, статистикой, видеть, что
				продаётся, приходы, отмены. Функционал такой&nbsp;же, как
				и&nbsp;в&nbsp;других программах, но&nbsp;организовано всё удобнее.
				Не&nbsp;нужно ничего, кроме телефона, планшета и&nbsp;ноутбука.
				А&nbsp;главное, не&nbsp;нужен бухгалтер-калькулятор.
			</>
		),
	},
	{
		heading: "90% людей не могут чего-то добиться, потому что боятся",
		textarea: (
			<>
				Совет всем, кто хочет открыть заведение, простой: позвоните
				Владимиру&nbsp;&mdash; Владимир откроет (это я&nbsp;про себя). Такой
				опыт у&nbsp;меня есть. У&nbsp;нас была своя консалтинговая
				компания&nbsp;&mdash; открывали рестораны со&nbsp;стороны кухни под
				ключ. Ну&nbsp;а&nbsp;если серьёзно, не&nbsp;бойтесь экспериментировать
				и&nbsp;шагайте вперёд. Не&nbsp;получилось? Идите в&nbsp;другую сторону.
				Нет денег? Возьмите в&nbsp;кредит. Не&nbsp;дают кредит? Найдите другие
				пути.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconEight)}>
				<img
					className={classNames(styles.icon, styles.icon__eight)}
					src={PromptIconEight}
					alt="Иконка 8"
					loading="lazy"
				/>
				<p>Нет денег? Возьмите кредит. Не&nbsp;дают? Найдите другие пути</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentNine)}>
				<Fancybox>
					<a
						data-fancybox="image9"
						href={MediaContentNine}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentNine}
							alt="как открыть фудтрак"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descNine)}>
					{/*Хотите открыть заведение? Позвоните Владимиру&nbsp;&mdash; Владимир*/}
					{/*откроет!*/}
					Хотите открыть заведение? <wbr /> Позвоните Владимиру
					<wbr />— Владимир откроет!
				</p>
			</div>
		),
		textarea2: (
			<>
				К&nbsp;примеру, в&nbsp;Питере есть человек, который повесил возле своего
				кухонного окна меню и&nbsp;продаёт там шаверму&nbsp;&mdash; собирает
				весь район. Алгоритм всегда одинаковый:{" "}
				<strong>
					выберите продукт, определитесь с&nbsp;неймингом и&nbsp;просчитайте все
					материальные ценности.
				</strong>{" "}
				Лайфхак для начинающих&nbsp;&mdash; очень много компаний по&nbsp;продаже
				оборудования дают консультации по&nbsp;открытию заведения под ключ.
				Причём не&nbsp;за&nbsp;отдельные деньги, а&nbsp;за&nbsp;то, что
				вы&nbsp;у&nbsp;них заказываете оборудование.
			</>
		),
	},
	{
		heading: "Люблю общепит за моментальный фидбэк",
		textarea: (
			<>
				Мы&nbsp;готовим продукт и&nbsp;получаем в&nbsp;ответ улыбку или обратно
				этим продуктом в&nbsp;лицо (шутка). Это нравится мне в&nbsp;общепите
				больше всего, и&nbsp;по&nbsp;этой причине я&nbsp;в&nbsp;нём остаюсь.
				Особенно мне нравится общаться с&nbsp;гостями-детьми. Они приходят
				и&nbsp;говорят: &laquo;Бородатый дядька, дай кыстыбый&raquo;. Иногда
				я&nbsp;не&nbsp;беру с&nbsp;них денег, потому что я&nbsp;собственник
				и&nbsp;могу себе это позволить.
			</>
		),
	},
]
